<template>
  <div>
    <TheTeCommonHeaderBriefing />
    <main class="pt-[4.375rem]">
      <slot />
    </main>
    <TheTeCommonFooter />
  </div>
</template>

<script setup lang="ts">
useHead({
  htmlAttrs: {
    "data-theme": 'techacademy',
  },
})
</script>

<script lang="ts">
import { defineNuxtComponent } from '#app'
import TheTeCommonHeaderBriefing from '~/components/Te/TeCommon/Header/TheTeCommonHeaderBriefing/TheTeCommonHeaderBriefing.vue'
import TheTeCommonFooter from '~/components/Te/TeCommon/Footer/TheTeCommonFooter/TheTeCommonFooter.vue'

const options = {
  components: {
    TheTeCommonHeaderBriefing,
    TheTeCommonFooter,
  },
}

export default defineNuxtComponent(options)
</script>

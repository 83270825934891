<template>
  <header class="te-header co-border-gray-300 fixed inset-x-0 top-0 border-b">
    <div class="co-container-lg navbar h-full px-4 py-0">
      <div class="flex-1">
        <!-- ロゴ -->
        <TeCommonHeaderLogoNoLink />
      </div>
      <div class="flex-none">
        <!-- SPナビゲーション開閉ボタン -->
        <button
          class="flex items-center pr-5 text-[1.25rem] outline-none lg:hidden"
          @click="isMenuOpen = !isMenuOpen"
        >
          <i
            class="fa fa-chevron-circle-down"
            aria-hidden="true"
          />
        </button>

        <!-- SPナビゲーション -->
        <Transition name="fade">
          <div
            v-show="isMenuOpen"
            class="te-collapse px-4"
          >
            <nav class="flex flex-col">
              <a
                class="py-3 text-co-gray-700 hover:text-co-gray-700 xl:px-4"
                href="#briefing-movies-wrap"
              >
                説明会動画
              </a>
              <a
                class="py-3 text-co-gray-700 hover:text-co-gray-700 xl:px-4"
                href="#curriculum"
              >
                カリキュラム
              </a>
              <a
                class="py-3 text-co-gray-700 hover:text-co-gray-700 xl:px-4"
                href="#output"
              >
                特徴
              </a>
              <a
                class="py-3 text-co-gray-700 hover:text-co-gray-700 xl:px-4"
                href="#price"
              >
                料金
              </a>
              <a
                class="py-3 text-co-gray-700 hover:text-co-gray-700 xl:px-4"
                href="#flow"
              >
                受講の流れ
              </a>
              <a
                class="py-3 text-co-gray-700 hover:text-co-gray-700 xl:px-4"
                href="#faq"
              >
                FAQ
              </a>
            </nav>
          </div>
        </Transition>

        <!-- PCナビゲーション -->
        <nav
          class="hidden px-4 lg:flex"
        >
          <a
            class="px-2 py-3 text-co-gray-700 hover:text-co-gray-700 xl:px-4"
            href="#briefing-movies-wrap"
          >
            説明会動画
          </a>
          <a
            class="px-2 py-3 text-co-gray-700 hover:text-co-gray-700 xl:px-4"
            href="#curriculum"
          >
            カリキュラム
          </a>
          <a
            class="px-2 py-3 text-co-gray-700 hover:text-co-gray-700 xl:px-4"
            href="#output"
          >
            特徴
          </a>
          <a
            class="px-2 py-3 text-co-gray-700 hover:text-co-gray-700 xl:px-4"
            href="#price"
          >
            料金
          </a>
          <a
            class="px-2 py-3 text-co-gray-700 hover:text-co-gray-700 xl:px-4"
            href="#flow"
          >
            受講の流れ
          </a>
          <a
            class="px-2 py-3 text-co-gray-700 hover:text-co-gray-700 xl:px-4"
            href="#faq"
          >
            FAQ
          </a>
        </nav>
        <!-- 申込むボタン -->
        <a
          class="inline-block rounded bg-co-primary-500 px-3 py-2 text-white hover:text-white"
          href="#apply"
          @click.prevent="scrollTo('apply')"
        >
          申込む
        </a>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { scrollTo } from '~/composables/useSmoothScroll'
import TeCommonHeaderLogoNoLink from "~/components/Te/TeCommon/Header/TeCommonHeaderLogoNoLink/TeCommonHeaderLogoNoLink.vue";

const isMenuOpen = ref(false);
</script>

<style scoped>
.te-header {
  z-index: 1030;
  height: 70px;
  @apply w-full bg-co-gray-50;
}

/* 開閉メニュー */
.te-collapse {
  @apply absolute top-[70px] left-0 w-full h-[calc(100vh-70px)] border-b border-gray-300 overflow-hidden;
  background-color: rgba(255, 255, 255, 0.97);
}

/* フェードアニメーション */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
